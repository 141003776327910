import createDiscountModal from 'assets/tutorial/create_discount_modal.png';
import coupons from 'assets/tutorial/coupons.png';
import coupons2 from 'assets/tutorial/coupons_2.png';
import coupons3 from 'assets/tutorial/coupons_3.png';
import unlimitedCoupon from 'assets/tutorial/unlimited_coupon.png';
import repeatableCoupon from 'assets/tutorial/repeatable_coupon.png';

import Navigation from '../Navigation';
import './help.css';
import { Link } from 'react-router-dom';

export default function DiscountsHelpPage() {
  return (
    <div className="helpContainer">
      <Navigation />
      <p>
        Discounts have two important components: <strong>Batch ID</strong> and{' '}
        <strong>Coupon </strong>(also referred to as{' '}
        <strong>Discount Code</strong> as well)
        <br />
        Batch ID is used for internal reference (it can be the name of a
        corporate for example), while the coupon is what the user will actually
        enter when they sign up
      </p>
      <p>
        New codes can be created from the <Link to="/discounts">Discounts</Link>{' '}
        page, by selecting <strong>Create Discount</strong>
      </p>
      <br />
      <p>
        The image below shows how to create 3 <strong>single use</strong>{' '}
        coupons that clients can use to get 100% (free) discovery memberships.
        <br />
        After clicking on create, the backoffice should show a modal with all
        the codes that were created.
      </p>
      <img src={createDiscountModal} />
      <img src={coupons} />
      <p>
        The above coupons can be shared with the clients. The contents of this
        modal (all the coupons for this batch id) can also be exported to CSV
        from the top right button.
      </p>
      <br /> <br />
      <p>
        Batch IDs can be reused to add other coupons to it. In the below
        example, a new coupon for Expert membership is being created for the
        batch id previously used in the same example.
        <br />
        <br />
        Since this is an <strong>unlimited coupon</strong>, you need to enter
        the coupon for the user. In the below example,{' '}
        <em>test_code_tutorial</em> will be the code that users input when they
        sign up.
      </p>
      <img src={unlimitedCoupon} />
      <p>
        This code will be displayed together with other codes with the same
        batch id
      </p>
      <img src={coupons2} />
      <p>
        For creating a repeatable code, follow the same steps but select{' '}
        <strong>repeatable</strong> and enter the number of times it can be
        used. In the below image, a code that can be used 4 times will be
        created
      </p>
      <img src={repeatableCoupon} />
      <p>
        This code will be displayed together with other codes with the same
        batch id
      </p>
      <img src={coupons3} />
      <p>
        Read more about how to deactivate coupons and the users that signed up
        with them <Link to="/help_discounts_edit">here</Link>
      </p>
    </div>
  );
}
