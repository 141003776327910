import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useGetAllBatchIdsQuery } from 'common/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useBatchId from './discountModalData/useBatchId';
import useCoupon from './discountModalData/useCoupon';
import useNumberCoupons from './discountModalData/useNumberCoupons';
import usePercentage from './discountModalData/usePercentage';

export default function CreateDiscounModal(props: Props) {
  const [unlimited, setUnlimited] = useState(false);
  const [repeteable, setRepetable] = useState(false);

  const [membershipType, setMembershipType] = useState<MembershipType>(
    'plan_without_genetics'
  );

  const { data: batchIds = [] } = useGetAllBatchIdsQuery();

  const { onCreatePressed, apiCouponError } = props;

  const {
    percentageValue,
    percentage,
    onPercentageBlur,
    onPercentageChangeText,
    percetageError,
    onPercentageFocus,
    isPercentageValid,
  } = usePercentage();

  const {
    batchId,
    batchIdError,
    onBatchIdFocus,
    onBatchIdChange,
    isBatchIdValid,
  } = useBatchId();

  const {
    numberCoupons,
    numberCouponsError,
    onNumberCouponsFocus,
    onNumberCouponsChange,
    onNumberCouponsBlur,

    setNumberCoupons,
    setNumberCouponsError,
    isNumberOfCouponsValid,

    numberOfUses,
    numberOfUsesError,
    onNumberUsesFocus,
    onNumberUsesChange,
    onNumberUsesBlur,

    setNumberOfUses,
    setNumberOfUsesError,
    isNumberOfUsesValid,
  } = useNumberCoupons();

  const {
    onCouponFocus,
    isCouponValid,

    coupon,
    setCoupon,
    couponError,
    setCouponError,
  } = useCoupon();

  const disableButton = useMemo(
    () =>
      percetageError.length > 0 ||
      batchIdError.length > 0 ||
      (unlimited && couponError.length > 0),
    [batchIdError.length, couponError, percetageError.length, unlimited]
  );

  const onCreateButtonPressed = useCallback(() => {
    const batchIdValid = isBatchIdValid();
    const percentageValid = isPercentageValid();
    const numberOfCouponsValid = !unlimited ? isNumberOfCouponsValid() : true;
    const numberOfUsesValid = repeteable ? isNumberOfUsesValid() : true;

    if (
      batchIdValid &&
      percentageValid &&
      numberOfCouponsValid &&
      numberOfUsesValid
    ) {
      onCreatePressed(
        batchId,
        membershipType,
        numberCoupons,
        percentageValue,
        unlimited,
        unlimited || repeteable ? coupon : '',
        repeteable,
        numberOfUses
      );
    }
  }, [
    batchId,
    coupon,
    isBatchIdValid,
    isNumberOfCouponsValid,
    isNumberOfUsesValid,
    isPercentageValid,
    membershipType,
    numberCoupons,
    numberOfUses,
    onCreatePressed,
    percentageValue,
    repeteable,
    unlimited,
  ]);

  useEffect(() => {
    if (apiCouponError) {
      setCouponError(apiCouponError);
    }
  }, [apiCouponError, setCouponError]);

  const clearTextFields = useCallback(() => {
    setNumberOfUsesError('');
    setNumberOfUses('1');
    setNumberCouponsError('');
    setNumberCoupons('1');
  }, [
    setNumberCoupons,
    setNumberCouponsError,
    setNumberOfUses,
    setNumberOfUsesError,
  ]);

  return (
    <div className="topModalContainer">
      <div className="discountCoupons createDiscountModal">
        <Autocomplete
          options={batchIds}
          freeSolo={true}
          onChange={(_event, newValue) => {
            onBatchIdChange(newValue ?? '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Batch ID"
              className="textField"
              variant="standard"
              type="text"
              onChange={({ target: { value } }) => onBatchIdChange(value)}
              onFocus={onBatchIdFocus}
              error={batchIdError.length > 0}
              helperText={batchIdError}
              onBlur={isBatchIdValid}
            />
          )}
        />

        {(unlimited || repeteable) && (
          <TextField
            label="Coupon"
            className="textField marginTop20"
            variant="standard"
            type="text"
            onChange={({ target: { value } }) => setCoupon(value)}
            onFocus={onCouponFocus}
            error={couponError.length > 0}
            helperText={couponError}
            onBlur={isCouponValid}
          />
        )}

        <TextField
          label="Percentage"
          className="textField marginTop20"
          type="number"
          variant="standard"
          value={percentage}
          onBlur={onPercentageBlur}
          onFocus={onPercentageFocus}
          onChange={({ target: { value } }) => onPercentageChangeText(value)}
          error={percetageError.length > 0}
          helperText={percetageError}
        />

        <FormControl className="marginTop40" fullWidth>
          <InputLabel id="discountTypeLabel">Discount type</InputLabel>

          <Select
            label="Discount type"
            labelId="discountTypeLabel"
            value={membershipType}
            onChange={({ target: { value } }) =>
              setMembershipType(value as MembershipType)
            }
          >
            <MenuItem value="plan">Expert</MenuItem>
            <MenuItem value="plan_without_genetics">Discovery</MenuItem>
            <MenuItem value="membership_upgrade">Expert upgrade</MenuItem>
          </Select>
        </FormControl>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="marginTop20"
        >
          <Grid item sm={3}>
            <TextField
              label="Number of coupons"
              className="textField"
              type="number"
              variant="standard"
              disabled={unlimited || repeteable}
              error={numberCouponsError.length > 0}
              helperText={numberCouponsError}
              onBlur={onNumberCouponsBlur}
              onFocus={onNumberCouponsFocus}
              onChange={({ target: { value } }) => onNumberCouponsChange(value)}
              value={numberCoupons}
            />
          </Grid>
          <Grid item sm={2} className="marginTop20">
            <FormControlLabel
              control={
                <Checkbox
                  checked={unlimited}
                  onChange={({ target: { checked } }) => {
                    clearTextFields();
                    setUnlimited(checked);
                    if (checked && repeteable) {
                      setRepetable(false);
                    }
                  }}
                />
              }
              label="Unlimited"
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              label="Number of uses"
              className="textField"
              type="number"
              variant="standard"
              disabled={!repeteable}
              error={numberOfUsesError.length > 0}
              helperText={numberOfUsesError}
              onBlur={onNumberUsesBlur}
              onFocus={onNumberUsesFocus}
              onChange={({ target: { value } }) => onNumberUsesChange(value)}
              value={numberOfUses}
            />
          </Grid>

          <Grid item sm={2} className="marginTop20">
            <FormControlLabel
              control={
                <Checkbox
                  checked={repeteable}
                  onChange={({ target: { checked } }) => {
                    clearTextFields();
                    setRepetable(checked);
                    if (checked && unlimited) {
                      setUnlimited(false);
                    }
                  }}
                />
              }
              label="Repeatable"
            />
          </Grid>
        </Grid>

        <Grid justifyContent="flex-end" container>
          <Button
            variant="contained"
            className="marginTop40 marginRight20"
            onClick={onCreateButtonPressed}
            disabled={disableButton}
          >
            Create
          </Button>
        </Grid>
      </div>
    </div>
  );
}

type MembershipType = 'plan' | 'plan_without_genetics' | 'membership_upgrade';

interface Props {
  onCreatePressed: (
    batchId: string,
    discountType: string,
    numberOfCoupons: string,
    percentValue: number,
    unlimited: boolean,
    coupon: string,
    repeatable: boolean,
    numberOfUses: string
  ) => void;

  apiCouponError?: string;
}
