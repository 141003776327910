import { useCallback, useMemo, useState } from 'react';

export default function usePercentage() {
  const [percentage, setPercentage] = useState('');
  const [percetageError, setPercentageError] = useState('');
  const percentageValue = useMemo(() => parseInt(percentage), [percentage]);

  const onPercentageFocus = useCallback(() => setPercentageError(''), []);

  const onPercentageBlur = useCallback(() => {
    if (
      isNaN(percentageValue) ||
      percentageValue < 0 ||
      percentageValue > 100
    ) {
      setPercentageError('Please enter a number between 0 and 100');
    } else {
      setPercentage(percentageValue.toString());
    }
  }, [percentageValue]);

  const onPercentageChangeText = useCallback(
    (text: string) => setPercentage(text),
    []
  );

  const isPercentageValid = useCallback(() => {
    if (
      isNaN(percentageValue) ||
      percentageValue < 0 ||
      percentageValue > 100
    ) {
      setPercentageError('Please enter a number between 0 and 100');
      return false;
    }

    return true;
  }, [percentageValue]);

  return {
    percentage,
    percentageValue,
    onPercentageBlur,
    onPercentageChangeText,
    percetageError,
    onPercentageFocus,
    isPercentageValid,
  };
}
