import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useMobile } from 'common/mobile';
import { useState } from 'react';

export default function DiscountsSearchForm(props: {
  batchId: string;
  onSearchPressed: (batchId: string) => void;
}) {
  const { onSearchPressed, batchId: batchIdProp } = props;
  const mobile = useMobile();

  const [batchId, setBatchId] = useState(batchIdProp);

  return (
    <Grid
      container
      alignItems="center"
      className={`inputsContainer${!mobile ? ' marginLeft20' : ''}`}
      justifyContent={mobile ? 'center' : 'flex-start'}
    >
      <Grid item xs={10} md={2}>
        <TextField
          className="textField"
          label="Batch Id"
          value={batchId}
          onChange={({ target: { value } }) => setBatchId(value)}
        />
      </Grid>

      <Grid item xs={10} md={1} container direction="column">
        <LoadingButton
          variant="contained"
          className="searchButton"
          onClick={() => onSearchPressed(batchId)}
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
