import { Button, Modal, Stack } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

export default function CalendarModal(props: Props) {
  const { open, onDateSelected, onClose } = props;
  const [date, setDate] = useState<Dayjs | null>(dayjs().add(1, 'day'));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Stack
          sx={{
            backgroundColor: 'white',
            padding: '16px',
            alignSelf: 'center',
          }}
        >
          <DateCalendar
            disableHighlightToday={true}
            minDate={dayjs().add(1, 'day')}
            value={date}
            onChange={setDate}
          />

          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            gap="10px"
            sx={{ marginTop: '20px' }}
          >
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() =>
                onDateSelected(date === null ? null : date.format('YYYY-MM-DD'))
              }
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </LocalizationProvider>
  );
}

interface Props {
  open: boolean;
  onDateSelected: (date: string | null) => void;
  onClose: () => void;
}
