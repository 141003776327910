import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useMobile } from 'common/mobile';
import validator from 'common/validator';
import { useCallback, useMemo, useState } from 'react';

export default function ChangePasswordForm(props: Props) {
  const mobile = useMobile();
  const { onChangeStatusPressed, enabled, onChangePasswordPressed } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordError, setPasswordError] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

  const validatePassword = useCallback(() => {
    if (!validator.validPassword(password)) {
      setPasswordError(
        'Password must be at least 8 characters, contain an uppercase letter, a number and a symbol.'
      );
    }
  }, [password]);

  const validateConfirmPassword = useCallback(() => {
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    }
  }, [confirmPassword, password]);

  const disableButtonCssClass = useMemo(
    () => (mobile ? 'marginTop40' : 'marginTop80'),
    [mobile]
  );

  return (
    <Grid
      item
      container
      direction="column"
      xs={10}
      md={4}
      className="marginTop20"
    >
      <TextField
        label="Password"
        className="textField"
        variant="standard"
        type="password"
        error={passwordError !== undefined}
        helperText={passwordError}
        onBlur={validatePassword}
        onFocus={() => setPasswordError(undefined)}
        onChange={({ target: { value } }) => setPassword(value)}
      />
      <TextField
        label="Confirm password"
        className="textField marginTop20"
        variant="standard"
        type="password"
        error={confirmPasswordError !== undefined}
        helperText={confirmPasswordError}
        onBlur={validateConfirmPassword}
        onFocus={() => setConfirmPasswordError(undefined)}
        onChange={({ target: { value } }) => setConfirmPassword(value)}
      />
      <LoadingButton
        variant="contained"
        className="loginButton"
        onClick={() => onChangePasswordPressed(password)}
        disabled={
          passwordError !== undefined || confirmPasswordError !== undefined
        }
      >
        Change password
      </LoadingButton>

      <LoadingButton
        variant="contained"
        className={`loginButton ${disableButtonCssClass}`}
        color="warning"
        onClick={onChangeStatusPressed}
      >
        {enabled ? 'Disable' : 'Enable'} user
      </LoadingButton>
    </Grid>
  );
}

interface Props {
  enabled: boolean;
  onChangeStatusPressed: () => void;
  onChangePasswordPressed: (password: string) => void;
}
