import { useCallback, useState } from 'react';

export default function useBatchId() {
  const [batchId, setBatchId] = useState('');
  const [batchIdError, setBatchIdError] = useState('');

  const onBatchIdFocus = useCallback(() => setBatchIdError(''), []);

  const onBatchIdChange = useCallback((text: string) => setBatchId(text), []);

  const isBatchIdValid = useCallback(() => {
    if (!batchId) {
      setBatchIdError('Please enter a batch id');
      return false;
    }

    return true;
  }, [batchId]);

  return {
    batchIdError,
    onBatchIdFocus,
    onBatchIdChange,

    isBatchIdValid,
    batchId,
  };
}
