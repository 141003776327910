import { useCallback, useState } from 'react';

export default function useCoupon() {
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');

  const onCouponFocus = useCallback(() => {
    setCouponError('');
  }, []);

  const isCouponValid = useCallback(() => {
    if (!coupon) {
      setCouponError('Please enter a coupon');
      return false;
    }
    if (coupon.length < 5) {
      setCouponError('Coupons must be at least 5 characters long');
      return false;
    }
  }, [coupon]);

  return {
    onCouponFocus,
    isCouponValid,

    coupon,
    setCoupon,
    couponError,
    setCouponError,
  };
}
