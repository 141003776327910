const PASSWORD_REGEX =
  /^(?!.*[\s])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])[a-zA-Z0-9^$*.[\]{}()?\-"!@#%&/,><':;|_~`]{8,99}/;
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const CARD_NUMBER_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|3(?:0[0-5]|[68][0-9])[0-9]{11}|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
const CARD_EXPIRY_REGEX = /^((0[0-9])|(1[0-2]))\/[0-9]{2}$/;

const validate = (value: string | undefined, regex: RegExp) => {
  if (!value) {
    return false;
  }

  return regex.test(value);
};

const validator = {
  validPassword: (password: string | undefined) =>
    validate(password, PASSWORD_REGEX),
};

export default validator;
