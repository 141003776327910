import {
  Alert,
  Button,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useMobile } from 'common/mobile';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  useChangePasswordMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useGetUserDetailsQuery,
  useUpdateGeneticsWebsiteMutation,
  useUpdateUserKitAtLabMutation,
} from '../../common/api';
import ChangePasswordForm from './ChangePasswordForm';
import { User } from './types';
import CalendarModal from './CalendarModal';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';

type UserKey = keyof Omit<
  User,
  'geneticsHash' | 'geneticsHashExpirationDate' | 'kitStatus'
>;
type Labels = { [key in UserKey]: string };

const labelMaps: Labels = {
  id: 'ID',
  username: 'AWS UUID',
  name: 'Name',
  createdAt: 'Created at',
  discountCode: 'Discount code',
  discountBatchId: 'Discount batch id',
  email: 'Email',
  enabled: 'Enabled',

  geneticsOrderAddress1: 'Genetics Order Address1',
  geneticsOrderAddress2: 'Genetics Order Address2',
  geneticsOrderCity: 'Genetics Order City',
  geneticsOrderCountry: 'Genetics Order Country',
  geneticsOrderPostCode: 'Genetics Order Post Code',
  hasGenetics: 'Has Genetics',

  kitBarcode: 'DNA Kit Barcode',
};

export default function UserDetailsScreen() {
  const { uuid = '' } = useParams<{ uuid: string }>();
  const mobile = useMobile();

  const { data: user } = useGetUserDetailsQuery(uuid);
  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();
  const [changePassword] = useChangePasswordMutation();
  const [updateGeneticsWebsite, { isLoading }] =
    useUpdateGeneticsWebsiteMutation();
  const [updateUserKitAtLab] = useUpdateUserKitAtLabMutation();

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCopiedToast, setShowCopiedToast] = useState(false);

  const onChangeStatusPressed = useCallback(() => {
    if (user) {
      if (user.enabled) {
        disableUser(user.username);
      } else {
        enableUser(user.username);
      }
    }
  }, [disableUser, enableUser, user]);

  const onChangePasswordPressed = useCallback(
    (password: string) => {
      if (user) {
        changePassword({ uuid: user.username, password })
          .then(() => {
            alert('Password changed');
          })
          .catch(() => {
            alert('Password change error');
          });
      }
    },
    [changePassword, user]
  );

  const filteredKeys = useMemo<UserKey[]>(() => {
    const keys = Object.keys(labelMaps) as UserKey[];

    if (!user?.hasGenetics) {
      return keys.filter(
        (key) =>
          !key.includes('genetics') ||
          key === 'hasGenetics' ||
          key === 'kitBarcode'
      );
    }

    return keys;
  }, [user?.hasGenetics]);

  const rowTable = useCallback(
    (field: UserKey, label: string) => {
      if (!user) {
        return null;
      }
      let value = user[field] ?? 'N / A';
      if (typeof user[field] === 'boolean') {
        value = value ? 'true' : 'false';
      }

      return (
        <TableRow key={field}>
          <TableCell component="td" className="cellBorder" colSpan={1}>
            <strong>{label}</strong>
          </TableCell>
          <TableCell component="td" className="cellBorder" colSpan={3}>
            <Typography>{value.toString()}</Typography>
          </TableCell>
        </TableRow>
      );
    },
    [user]
  );

  const fieldsRows = useMemo(() => {
    if (!user) {
      return null;
    }
    return filteredKeys.map((key) => rowTable(key, labelMaps[key]));
  }, [filteredKeys, rowTable, user]);

  const kitStatus = useMemo(() => {
    if (!user || !user.hasGenetics) {
      return null;
    }

    return (
      <TableRow key="kitStatus">
        <TableCell component={'td'} className="cellBorder">
          <strong>DNA Kit Status</strong>
        </TableCell>

        <TableCell component={'td'} className="cellBorder" colSpan={3}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ marginRight: '100px' }}
          >
            <Typography>{user.kitStatus}</Typography>

            {user.kitStatus === 'registered' && (
              <Button
                variant="contained"
                onClick={() => {
                  if (
                    confirm('Are you sure you want to mark this kit as AT LAB?')
                  ) {
                    updateUserKitAtLab({ id: user.id, uuid: user.username });
                  }
                }}
              >
                At lab
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }, [updateUserKitAtLab, user]);

  const geneticsWebsiteRow = useMemo(() => {
    if (!user?.hasGenetics || !user) {
      return null;
    }
    const geneticsDisabled =
      !user.geneticsHash ||
      dayjs(user.geneticsHashExpirationDate).endOf('day').isBefore(dayjs());

    const link = `https://sandy.syd.life/${user.geneticsHash}`;

    const actionButton = geneticsDisabled ? (
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={() => setShowCalendar(true)}
      >
        Activate
      </LoadingButton>
    ) : (
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={'warning'}
        onClick={() =>
          updateGeneticsWebsite({
            userId: user.id,
            uuid: user.username,
            expirationDate: null,
          })
        }
      >
        Disable
      </LoadingButton>
    );

    return (
      <TableRow key="geneticsWebsite">
        <TableCell component={'td'} className="cellBorder">
          <strong>Genetics Website</strong>
        </TableCell>
        <TableCell component={'td'} className="cellBorder" colSpan={1}>
          {geneticsDisabled ? (
            <Typography>N / A</Typography>
          ) : (
            <Stack direction={'row'} alignItems={'center'} gap={'40px'}>
              <Stack>
                <a href={link} target="_blank" rel="noreferrer">
                  {link}
                </a>
              </Stack>
              <Button
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  setShowCopiedToast(true);
                }}
              >
                COPY
              </Button>
            </Stack>
          )}
        </TableCell>
        <TableCell component={'td'} className="cellBorder" colSpan={1}>
          Expires:{' '}
          {user.geneticsHashExpirationDate
            ? dayjs(user.geneticsHashExpirationDate).format('DD MMM YYYY')
            : 'N / A'}
        </TableCell>
        <TableCell component={'td'} className="cellBorder" colSpan={1}>
          {actionButton}
        </TableCell>
      </TableRow>
    );
  }, [isLoading, updateGeneticsWebsite, user]);

  if (!user) {
    return null;
  }

  return (
    <div className="userDetailsContainer">
      <Table className={`detailsTable${mobile ? ' detailsTableFull' : ''}`}>
        <TableBody>
          {fieldsRows}
          {kitStatus}
          {geneticsWebsiteRow}
        </TableBody>
      </Table>

      <ChangePasswordForm
        onChangeStatusPressed={onChangeStatusPressed}
        onChangePasswordPressed={onChangePasswordPressed}
        enabled={user.enabled}
      />

      <CalendarModal
        open={showCalendar}
        onDateSelected={(date) => {
          updateGeneticsWebsite({
            userId: user.id,
            uuid: user.username,
            expirationDate: date,
          });
          setShowCalendar(false);
        }}
        onClose={() => setShowCalendar(false)}
      />

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={showCopiedToast}
        onClose={() => setShowCopiedToast(false)}
        autoHideDuration={2000}
      >
        <Alert severity="success">Copied to clipboard</Alert>
      </Snackbar>
    </div>
  );
}
