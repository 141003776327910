import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Navigation() {
  const navigate = useNavigate();

  const onLogoutPressed = useCallback(() => {
    Auth.signOut().then(() => navigate('/', { replace: true }));
  }, [navigate]);

  return (
    <div className="helpNavigation">
      <div className="section">
        <strong>Backoffice</strong>
        <Link to="/users">Users</Link>
        <Link to="/discounts">Discounts</Link>

        <Button
          variant="contained"
          className="marginTop20 marginBottom20"
          onClick={onLogoutPressed}
        >
          Log out
        </Button>
      </div>

      <div className="section">
        <strong>Help</strong>
        <Link to="/help_users">Searching for a user</Link>
        <Link to="/help_discounts">Creating a discount</Link>
        <Link to="/help_discounts_edit">Deactivating users &amp; coupons</Link>
      </div>
    </div>
  );
}
