import couponsSearch from 'assets/tutorial/coupons_search.png';
import couponsModal from 'assets/tutorial/coupons_modal.png';
import couponsUsers from 'assets/tutorial/coupons_users.png';
import couponsUserDeactivated from 'assets/tutorial/coupons_user_deactivated.png';
import userDeactivateDetails from 'assets/tutorial/user_deactivated_details.png';

import Navigation from '../Navigation';

export default function CouponsHelpPage() {
  return (
    <div className="helpContainer">
      <Navigation />

      <p>
        In order to search for coupons, you need to enter a part of the batch
        id. While you don&apos;t have to enter it fully, please bear in mind
        that it is <strong>case sensitive</strong>
      </p>

      <p>
        The below image show all the discounts that start with
        &quot;DemoCodeT&quot;
      </p>

      <img src={couponsSearch} />

      <p>
        You can check which coupons have been used. In the following example,
        the last coupon has been used. Clicking on the coupon, will open a new
        tab with all the users that have used it.
      </p>

      <img src={couponsModal} />
      <img src={couponsUsers} />

      <p>
        The coupons that have been used have the option to deactivate the users
        that have signed up with them. All coupons have the options to be
        deactivated. In the top right, you have the option to{' '}
        <strong>deactivate all coupons</strong> and{' '}
        <strong>all the users that used any of these coupons</strong>
      </p>

      <p>
        After deactivating users, you can double check the user&apos;s status
        from the user details (click on the coupon, and then on the user&apos;s
        UUID)
      </p>

      <img src={couponsUserDeactivated} />
      <img src={userDeactivateDetails} />
    </div>
  );
}
