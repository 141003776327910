import { DataGrid } from '@mui/x-data-grid';

import { DiscountCode } from './types';
import './discounts.css';

export default function DiscountsTable(props: Props) {
  const { discounts, setSelectedDiscount } = props;

  return (
    <DataGrid
      rows={discounts.map((item) => ({
        ...item,
        id: `${item.batchId}`,
      }))}
      columns={columns}
      onRowClick={(params) => setSelectedDiscount(params.row as DiscountCode)}
      sx={{
        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
        '& .MuiDataGrid-cell:focus': { outline: 'none' },
      }}
    />
  );
}

type GridColumns = React.ComponentProps<typeof DataGrid>['columns'];

const columns: GridColumns = [
  {
    field: 'batchId',
    headerName: 'Batch ID',
    flex: 0.2,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 0.33,
  },
  {
    field: 'numberOfCoupons',
    headerName: 'Number of coupons',
    flex: 0.33,
  },
];

interface Props {
  discounts: DiscountCode[];
  setSelectedDiscount: (discount: DiscountCode) => void;
}
