import searchNoFilters from 'assets/tutorial/users/search_no_filters.png';
import searchUsersDelmic from 'assets/tutorial/users/search_users_delmic.png';
import searchUsersCSV from 'assets/tutorial/users/search_users_csv.png';
import usersClickUUID from 'assets/tutorial/users/users_click_uuid.png';

import userDetailsNoGenetics from 'assets/tutorial/users/user_no_genetics.png';
import userDetailsGenetics from 'assets/tutorial/users/user_genetics.png';
import userGeneticsWebsiteActivate from 'assets/tutorial/users/user_genetics_website_activate.png';
import userGeneticsWebsiteDeactivate from 'assets/tutorial/users/user_genetics_website_enabled.png';
import userGeneticsWebsiteDeactivated from 'assets/tutorial/users/user_genetics_website_disabled.png';

import { Link } from 'react-router-dom';
import './help.css';
import Navigation from '../Navigation';

export default function UsersHelpPage() {
  return (
    <div className="helpContainer">
      <Navigation />
      <p>
        In the users section of the backoffice, you can view and search for
        users. You can search for users by <strong>email</strong>,{' '}
        <strong>Discount code (coupon)</strong>,{' '}
        <strong>Discount batch id</strong> (to read more about discount you can
        go <Link to="help_discounts">here</Link>) and <strong>name</strong>
      </p>
      <p>
        If you perform a search without any filters (without any of the fields
        completed) you will see a list of all users, sorted descendingly by
        their creation date.
      </p>
      <p>The image below shows such a case</p>
      <img src={searchNoFilters} />
      <p>Example of filtering by discount code</p>
      <img src={searchUsersDelmic} />
      <p>
        Selecting CSV will export the users for the current filter.{' '}
        <strong>
          <em>
            Please note that CSV export doesn&apos;t work for searches without
            any filters
          </em>
        </strong>
      </p>
      <img src={searchUsersCSV} />
      <p>
        Clicking on the UUID of an user will take you to the user details page,
        where you can view details about their genetics order (if applicable),
        change their password or disable their access.{' '}
      </p>
      <p>
        <strong>IMPORTANT</strong> The UUID is used as an order number for
        Eurofins, so you can use this as a reference if there any issues with an
        order
      </p>
      <img src={usersClickUUID} />
      <p>
        Users who have placed a genetic order will have the details of the order
        displayed, while those who haven&apos;t won&apos;t see the corresponding
        section.
      </p>
      <img src={userDetailsNoGenetics} />
      <img src={userDetailsGenetics} />

      <p>
        For allowing a practitioner access to a user&apos;s genetics, select
        &nbsp;
        <strong>Activate</strong>
      </p>
      <img src={userGeneticsWebsiteActivate} />

      <p>
        From the displayed calendar, select the date you want the access to the
        genetics website to expire. After the corresponding date, the
        practitioner will no longer be able to access the genetics. To disable
        the access before the expiration date, select{' '}
        <strong>Deactivate</strong>
      </p>
      <img src={userGeneticsWebsiteDeactivate} />
      <img src={userGeneticsWebsiteDeactivated} />
    </div>
  );
}
