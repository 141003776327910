import { User } from './types';
import { DataGrid } from '@mui/x-data-grid';

import './users.css';
import { Link } from 'react-router-dom';

export default function UsersTable(props: Props) {
  const { page, users, rowCount, onPageChange } = props;

  return (
    <DataGrid
      rows={users}
      columns={columns}
      rowCount={rowCount}
      paginationMode="server"
      onPaginationModelChange={(params) => onPageChange(params.page)}
      paginationModel={{ page, pageSize: 20 }}
      pageSizeOptions={[20]}
      autoHeight={true}
    />
  );
}

type GridColumns = React.ComponentProps<typeof DataGrid>['columns'];

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 0.05,
  },
  {
    field: 'username',
    headerName: 'AWS UUID',
    flex: 0.2,
    renderCell: (params) => {
      return <Link to={`/users/${params.value}`}>{params.value}</Link>;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 0.17,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.17,
  },
  { field: 'discountCode', headerName: 'Discount Code', flex: 0.17 },
  { field: 'discountBatchId', headerName: 'Discount Batch Id', flex: 0.17 },
  { field: 'barcode', headerName: 'DNA Kit Barcode', flex: 0.17 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 0.1,
  },
];

interface Props {
  users: User[];
  rowCount: number;
  page: number;
  onPageChange: (pageNumber: number) => void;
}
