import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

export const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>();

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        setLoggedIn(session.isValid());
      })
      .catch(() => setLoggedIn(false));
  }, []);

  return loggedIn;
};
