import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginScreen from './pages/login/LoginScreen';
import UsersScreen from 'pages/users/UsersScreen';
import { StyledEngineProvider } from '@mui/material/styles';
import UserDetailsScreen from 'pages/users/UserDetailsScreen';
import { Provider } from 'react-redux';
import store from 'store/store';
import DiscountsScreen from 'pages/discounts/DiscountsScreen';
import UsersHelpPage from 'pages/tutorial/UsersHelpPage';
import DiscountsHelpPage from 'pages/tutorial/DiscountsHelpPage';
import CouponsHelpPage from 'pages/tutorial/CouponsHelpPage';
import reportWebVitals from 'reportWebVitals';
import React, { useEffect, useRef } from 'react';
import { apiClient } from 'common/client';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8d09f7',
    },
  },
});

const App = () => {
  const router = createBrowserRouter([
    { path: '/', element: <LoginScreen /> },
    { path: '/users', element: <UsersScreen /> },
    { path: '/discounts', element: <DiscountsScreen /> },
    { path: '/users/:uuid', element: <UserDetailsScreen /> },
    { path: '/help_users', element: <UsersHelpPage /> },
    { path: '/help_discounts', element: <DiscountsHelpPage /> },
    { path: '/help_discounts_edit', element: <CouponsHelpPage /> },
  ]);

  return <RouterProvider router={router} />;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
