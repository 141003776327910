import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useMobile } from 'common/mobile';
import { useState } from 'react';
import './users.css';

export default function UsersSearchForm(props: Props) {
  const {
    onSearchPressed,
    onCSVPressed,
    searching,
    loadingCSV,

    email: emailProps,
    batchId: batchIdProp,
    discountCode: discountCodeProp,
    name: nameProp,
    barcode: barcodeProp,
  } = props;
  const mobile = useMobile();

  const [email, setEmail] = useState(emailProps);
  const [discountCode, setDiscountCode] = useState(discountCodeProp);
  const [batchId, setBatchId] = useState(batchIdProp);
  const [name, setName] = useState(nameProp);
  const [barcode, setBarcode] = useState(barcodeProp);

  return (
    <Grid
      container
      alignItems="center"
      className={`inputsContainer${!mobile ? ' marginLeft20' : ''}`}
      justifyContent={mobile ? 'center' : 'flex-start'}
      sx={{ width: 'calc(100% - 30px)' }}
    >
      <Grid item xs={10} md={2}>
        <TextField
          className="textField"
          label="Email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </Grid>

      <Grid item xs={10} md={2} className="marginLeft20">
        <TextField
          className="textField"
          label="Discount Code"
          value={discountCode}
          onChange={({ target: { value } }) => setDiscountCode(value)}
        />
      </Grid>

      <Grid item xs={10} md={1.5} className="marginLeft20">
        <TextField
          className="textField"
          label="Batch Id"
          value={batchId}
          onChange={({ target: { value } }) => setBatchId(value)}
        />
      </Grid>

      <Grid item xs={10} md={1.5} className="marginLeft20">
        <TextField
          className="textField marginLeft20"
          label="Name"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </Grid>

      <Grid item xs={10} md={1.5} className="marginLeft20">
        <TextField
          className="textField marginLeft20"
          label="Barcode"
          value={barcode}
          onChange={({ target: { value } }) => setBarcode(value)}
        />
      </Grid>

      <Grid
        item
        xs={10}
        md={1}
        container
        direction="column"
        className="marginLeft40"
      >
        <LoadingButton
          variant="contained"
          className="searchButton"
          loading={searching}
          onClick={() =>
            onSearchPressed(
              email ?? '',
              discountCode ?? '',
              batchId ?? '',
              name ?? '',
              barcode ?? ''
            )
          }
        >
          Search
        </LoadingButton>
      </Grid>

      <Grid item xs={10} md={1} container direction="column">
        <LoadingButton
          variant="contained"
          className="searchButton"
          loading={loadingCSV}
          disabled={!email && !discountCode && !batchId && !name}
          onClick={() =>
            onCSVPressed(
              email ?? '',
              discountCode ?? '',
              batchId ?? '',
              name ?? '',
              barcode ?? ''
            )
          }
        >
          CSV
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

interface Props {
  searching: boolean;
  loadingCSV: boolean;
  email: string;
  discountCode: string;
  batchId: string;
  name: string;
  barcode: string;

  onSearchPressed: (
    email: string,
    discountCode: string,
    batchId: string,
    name: string,
    barcode: string
  ) => void;

  onCSVPressed: (
    email: string,
    discountCode: string,
    batchId: string,
    name: string,
    barcode: string
  ) => void;
}
